!function ($) {
    "use strict";
    $.blockUI.defaults.message = '<div></div>';
    $.blockUI.defaults.overlayCSS.backgroundColor = '#fff';
    $.blockUI.defaults.overlayCSS.opacity = 1;
    $.blockUI.defaults.css.border = 'none';
    $.blockUI.defaults.onBlock = function (e) {
        this.parentElement.className += " blocked";
    };
    $.blockUI.defaults.onUnblock = function (e) {
        var classes = e.className;
        e.className = classes.replace(" blocked", "");
    };

    Number.prototype.formatMoney = function(c, d, t, e = true){
        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d == undefined ? "." : d,
            t = t == undefined ? "," : t,
            s = n < 0 && e ? "-" : "",
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };
}(window.jQuery);

!function($) {
    "use strict";

    var Notification = function() {};

    //typed notificaiton
        Notification.prototype.success = function(text) {
            $.Notification.notify('success', '', text);
        },
        Notification.prototype.error = function(text) {
            $.Notification.notify('error', '', text);
        },
        Notification.prototype.warning = function(text) {
            $.Notification.notify('warning', '', text);
        },
        Notification.prototype.info = function(text) {
            $.Notification.notify('info', '', text);
        },
        Notification.prototype.custom = function(text) {
            $.Notification.notify('custom', '', text);
        },
        //simple notificaiton
        Notification.prototype.notify = function(style, title, text) {
            var icon = 'fa fa-adjust';
            if(style == "error"){
                icon = "fa fa-exclamation";
            }else if(style == "warning"){
                icon = "fa fa-warning";
            }else if(style == "success"){
                icon = "fa fa-check";
            }else if(style == "custom"){
                icon = "fa fa-flag";
            }else if(style == "info"){
                icon = "fa fa-question";
            }else{
                icon = "fa fa-adjust";
            }
            $.notify({
                title: title,
                text: text,
                image: "<i class='"+icon+"'></i>"
            }, {
                style: 'metro',
                className: style,
                globalPosition: 'top right',
                showAnimation: "show",
                showDuration: 0,
                hideDuration: 0,
                autoHide: true,
                clickToHide: true
            });
        },
        //auto hide notification
        Notification.prototype.autoHideNotify = function (style,position, title, text) {
            var icon = "fa fa-adjust";
            if(style == "error"){
                icon = "fa fa-exclamation";
            }else if(style == "warning"){
                icon = "fa fa-warning";
            }else if(style == "success"){
                icon = "fa fa-check";
            }else if(style == "custom"){
                icon = "md md-album";
            }else if(style == "info"){
                icon = "fa fa-question";
            }else{
                icon = "fa fa-adjust";
            }
            $.notify({
                title: title,
                text: text,
                image: "<i class='"+icon+"'></i>"
            }, {
                style: 'metro',
                className: style,
                globalPosition:position,
                showAnimation: "show",
                showDuration: 0,
                hideDuration: 0,
                autoHideDelay: 5000,
                autoHide: true,
                clickToHide: true
            });
        },
        //confirmation notification
        Notification.prototype.confirm = function(style,position, title) {
            var icon = "fa fa-adjust";
            if(style == "error"){
                icon = "fa fa-exclamation";
            }else if(style == "warning"){
                icon = "fa fa-warning";
            }else if(style == "success"){
                icon = "fa fa-check";
            }else if(style == "custom"){
                icon = "md md-album";
            }else if(style == "info"){
                icon = "fa fa-question";
            }else{
                icon = "fa fa-adjust";
            }
            $.notify({
                title: title,
                text: 'Are you sure you want to do nothing?<div class="clearfix"></div><br><a class="btn btn-sm btn-white yes">Yes</a> <a class="btn btn-sm btn-danger no">No</a>',
                image: "<i class='"+icon+"'></i>"
            }, {
                style: 'metro',
                className: style,
                globalPosition:position,
                showAnimation: "show",
                showDuration: 0,
                hideDuration: 0,
                autoHide: false,
                clickToHide: false
            });
            //listen for click events from this style
            $(document).on('click', '.notifyjs-metro-base .no', function() {
                //programmatically trigger propogating hide event
                $(this).trigger('notify-hide');
            });
            $(document).on('click', '.notifyjs-metro-base .yes', function() {
                //show button text
                alert($(this).text() + " clicked!");
                //hide notification
                $(this).trigger('notify-hide');
            });
        },
        //init - examples
        Notification.prototype.init = function() {},
        //init
        $.Notification = new Notification, $.Notification.Constructor = Notification
}(window.jQuery);


!function($) {
    "use strict";

    var Alert = function() {};

    //typed alert
    Alert.prototype.success = function(text, title = '') {
        $.Alert.swal(text, title, 'success');
    };
    Alert.prototype.error = function(text, title = '') {
        $.Alert.swal(text, title, 'error');
    };
    Alert.prototype.info = function(text, title = '') {
        $.Alert.swal(text, title, 'info');
    };
    Alert.prototype.warning = function(text, title = '') {
        $.Alert.swal(text, title, 'warning');
    };
    Alert.prototype.clean = function(text, title = '') {
        $.Alert.swal(text, title);
    };
    Alert.prototype.clean = function(text, title = '') {
        $.Alert.swal(text, title);
    };
    Alert.prototype.confirm = function(callback) {
        swal({
            title: "Atenção!",
            text: "Você está prestes a executar uma ação irreversível, você está certo disto?",
            // type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#F44336",
            confirmButtonText: "Sim",
            cancelButtonText: 'Não',
            closeOnConfirm: false,
            html: false
        }, function(){
            callback();

        });
    };

    // swal thing
    Alert.prototype.swal = function(text, title, type) {
        swal({
            title: title,
            text: text,
            type: type
        });
    };

    //init
    $.Alert = new Alert, $.Alert.Constructor = Alert
}(window.jQuery);
